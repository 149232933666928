<template>
  <div id="app" class="app-body">
    <div>
      <div class="spacer"></div>
      <div class="columns is-vcentered">
        <div class="column">
          <p class="is-title is-secondary is-bold my-5 py-5">
            Brand <span class="is-primary">Research</span>
          </p>
          <p class="mt-4">
            To enable the next generation of brands in Pakistan, it’s necessary
            to know the brandlandscape. The Brandeeq team understands it, as
            we’ve done the research to prove it.
          </p>
          <p class="mt-4">
            We examined 100 Pakistani brands as a base sample: some
            long-established, some new, some public, some private.
          </p>
          <p class="mt-4">
            We considered aspects, including their brand names, deconstructed
            logos, considered visual identities, and looked at slogans or
            taglines.
          </p>
        </div>
        <div class="column is-1"></div>
        <div class="column has-text-right">
          <img
            src="@/assets/img/brand_research/brand_research_illustration.svg"
          />
        </div>
      </div>
      <div class="spacer"></div>
      <div class="columns">
        <div class="column has-text-centered">
          <p class="is-title is-primary is-bold">
            <span class="is-secondary"> The </span>Results
            <span class="is-secondary">of Our Analysis</span>
          </p>
          <p class="py-4">
            Just three observations of many from our examination of Pakistani
            brands.
          </p>
        </div>
      </div>
      <div class="spacer-small"></div>
      <div class="columns is-vcentered">
        <div class="column">
          <p class="is-subtitle is-secondary is-bold my-5 py-5">Brand names</p>
          <p class="mt-4">
            Over 50 percent of the brands examined are what we categorize
            adopted real word/descriptive names. Essentially, names of people,
            places, or services. In naming science, these types of names can
            require greater resources to distinguish and protect.
          </p>
        </div>
        <div class="column is-1"></div>
        <div class="column has-text-right">
          <img src="@/assets/img/brand_research/brand_name_illustration.svg" />
        </div>
      </div>
      <div class="spacer"></div>
      <div class="columns is-vcentered">
        <div class="column has-text-right">
          <img src="@/assets/img/brand_research/slogan_illustration.svg" />
        </div>
        <div class="column is-1"></div>
        <div class="column">
          <p class="is-subtitle is-secondary is-bold my-5 py-5">
            Slogans or discriptors
          </p>
          <p class="mt-4">
            Some say slogans, some say taglines, some say descriptors. Around 25
            percent of the brands we considered incorporated one as part of
            their logo. If you are going to have one, make sure it succinctly
            conveys what makes your brand special, and create it at the right
            point in the process.
          </p>
        </div>
      </div>
      <div class="spacer"></div>
      <div class="columns is-vcentered">
        <div class="column">
          <p class="is-subtitle is-secondary is-bold my-5 py-5">
            Colour choices
          </p>
          <p class="mt-4">
            Over 60 percent of the brands examined utilize the same three
            colors, either as a primary or secondary color in their visual
            identity. Within that practice, only 3-4 shades of each tended to
            dominate. This alludes to a lack of variance in contemporary graphic
            design practices in the country.
          </p>
        </div>
        <div class="column is-1"></div>
        <div class="column has-text-right">
          <img
            src="@/assets/img/brand_research/color_choices_illustration.svg"
          />
        </div>
      </div>
      <div class="spacer"></div>
    </div>
    <!-- loading images beforehand -->
    <img src="@/assets/img/home/explainer-1.svg" style="display: none" />
    <img src="@/assets/img/home/explainer-2.svg" style="display: none" />
    <img src="@/assets/img/home/explainer-3.svg" style="display: none" />
    <img src="@/assets/img/home/explainer-4.svg" style="display: none" />
  </div>
</template>

<script>
export default {
  name: "BuildBrands",
  data() {
    return {
      explainerHover: 1,
    };
  },

  methods: {
    setExplainerHover(val) {
      this.explainerHover = val;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
