<template>
  <div id="app" class="app-body">
    <div>
      <div class="spacer"></div>
      <div class="columns is-vcentered head-bg p-0 m-0">
        <div class="column has-text-white" style="padding: 54px">
          <p class="is-subtitle-small">Case Study</p>
          <p class="is-title is-bold">ROADS</p>
        </div>
        <div
          class="column has-text-right p-0 m-0 pt-5"
          style="display: flex; justify-content: end"
        >
          <img src="@/assets/img/case_studies/roads/hero.svg" />
        </div>
      </div>

      <div class="spacer-small"></div>
      <div class="columns">
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">Introduction</p>
          <p class="mt-4">
            ROADS, an acronym of Research on Advancement and Development of
            Social Sciences, is a Pakistani-based, international think-tank,
            formed in 2022.
          </p>
          <p class="mt-4">
            Among its aims is to unify a wide range of social science scholars
            and academics, to advance the quality of Pakistani’s social sciences
            academy.
          </p>
          <p class="mt-4">
            ROADS is an energetic contributor to publications, conferences and
            seminars.
          </p>
          <p class="is-title is-secondary is-bold mt-5 pt-5">Challenge</p>
          <p class="mt-4">
            ROADS had already determined its name, and had a version of a
            logomark, when it first came into contact with Brandeeq.
          </p>
          <p class="mt-4">
            In our assessment, while there was a concept, the design execution
            of it wasn’t optimal. So, what ROADS had was a new brand asset, but
            one that could work a lot harder, professionally-speaking.
          </p>
          <p class="mt-4">
            The brand owner was an advocate for the concept, though nevertheless
            knew that the design work could be improved, with some fresh input.
          </p>
        </div>
        <div class="column has-text-centered">
          <img src="@/assets/img/case_studies/roads/img-1.svg" />
        </div>
      </div>

      <div class="spacer"></div>
      <div class="columns is-vcentered">
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">Our Response</p>

          <p class="mt-4">
            While keeping the concept, Brandeeq evolved the logomark and its
            composition and created corresponding identity standards to support
            consistent application. This made for a more functional and
            impactful brand asset.
          </p>
          <p class="mt-4">
            And once the logomark was final, we created several, foundational
            brand templates for ROADS, with the logomark and matching identity
            standards properly reflected throughout.
          </p>
        </div>
        <div class="column has-text-centered">
          <img src="@/assets/img/case_studies/roads/img-2.svg" />
        </div>
      </div>
      <div class="spacer"></div>

      <div class="columns">
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">Results</p>
          <p class="mt-4">
            ROADS Executive Director, Dr. Ahmed Waqas Waheed, said:
          </p>

          <p class="mt-4 speech">
            “Conceptually, I knew what I wanted for the ROADS brand. It did not
            take long for Brandeeq team to listen, analyse, and then promptly
            offer several evolutions, one of which we settled for as our master
            logomark. That’s the visual identity that we use today and our focus
            is on ensuring that it’s applied consistently, in every situation.
          </p>
          <p class="mt-4 speech">
            Brandeeq provided a unique, customer experience, tailored not only
            to my requirements but also to my convenience.”
          </p>
          <div class="spacer-small"></div>
          <p>
            Are you intrigued? Message us at
            <a href="mailto:contact@brandeeq.com">contact@brandeeq.com</a> and
            share what’s on your mind.
          </p>
        </div>
        <div class="column has-text-centered">
          <img src="@/assets/img/case_studies/roads/img-3.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuildBrands",
  data() {
    return {
      explainerHover: 1,
    };
  },

  methods: {
    setExplainerHover(val) {
      this.explainerHover = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.head-bg {
  background: #883934;
}
</style>
