<template>
  <div id="app" class="app-body">
    <div>
      <div class="spacer"></div>
      <div class="columns is-vcentered head-bg p-0 m-0">
        <div class="column is-secondary" style="padding: 54px">
          <p class="is-subtitle-small">Case Study</p>
          <p class="is-title is-bold">Tourly</p>
        </div>
        <div
          class="column has-text-right p-0 m-0 pt-5"
          style="display: flex; justify-content: end"
        >
          <img src="@/assets/img/case_studies/tourly/hero.svg" />
        </div>
      </div>

      <div class="spacer"></div>
      <div class="columns">
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">Introduction</p>
          <p class="mt-4">
            Made to capitalize on Pakistan’s growing tourism industry, Tourly
            aims to become a one-stop-shop that offers entire packaged tours as
            well as access to vendors and travel agents through its mobile
            application.
          </p>
          <p class="is-title is-secondary is-bold mt-5 pt-5">Challenge</p>
          <p class="mt-4">
            To create a distinct brand promise that offers differential value to
            customers. Although Tourly aimed to revolutionize the tourism
            industry through digitization, it was not exactly the first business
            operating in this domain.
          </p>
          <p class="mt-4">
            Most tech-based tourism startups were milking the first part of
            their description “tech”. This was accurate in terms of explaining
            the added value these would offer on top of the existing,
            conventional tourism services.
          </p>
          <p class="mt-4">
            Tourly had to differentiate itself beyond this by employing a brand
            promise that could connect to people's emotions.
          </p>
        </div>
        <div class="column has-text-centered">
          <img src="@/assets/img/case_studies/tourly/img-1.svg" />
        </div>
      </div>

      <div class="spacer-small"></div>
      <div class="columns">
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">Our Response</p>

          <p class="mt-4">
            A brand promise that adds a human touch by including emotional
            appeal for customers in their perceived experience with Tourly.
          </p>
          <p class="mt-4">
            Deep research into the tourism industry and primary investigation
            led the Brandeeq team to conclude how emotion played a central role
            in enhancing tourists' experiences; to make good memories seemed to
            be a dominant reason most tourists partook in excursions.
          </p>
          <p class="mt-4">
            Brandeeq's standard creative process generated five distinct brand
            promise options to Tourly; these options reflected findings from the
            Brandeeq team's research whilst also embodying creativity and a
            functional purpose.
          </p>
        </div>
        <div class="column has-text-centered">
          <img src="@/assets/img/case_studies/tourly/img-2.svg" />
        </div>
      </div>

      <div class="columns">
        <div class="column">
          <p class="is-title is-secondary is-bold">Results</p>
          <p class="mt-4">
            Tourly selected one among the five options offered to them. Their
            adoption of the brand promise was done wholeheartedly and they
            showed great admiration for the process that went into creating the
            options.
          </p>

          <p class="mt-4">
            <span class="speech"
              >“Tech-y people like me waste a lot of time (while looking to
              create brand assets)”,</span
            >
            said Atif Malik, founder of Tourly.
          </p>
          <p class="mt-4 speech">
            “My experience with them (Brandeeq) was seamless… Keep helping
            startups like mine!”
          </p>
          <p class="mt-4 speech">
            “The options provided perfectly reflected what Tourly was looking
            for and our choice (touring memories), conveys deeper emotions that
            help customers take away an exceptional experience. A seamless
            interaction with Tourly removes bad experiences within the user
            journey, leaving behind only good memories; Brandeeq helped us
            realize the potential in making such a promise”
          </p>
          <div class="spacer-small"></div>
          <p>
            Are you intrigued? Message us at
            <a href="mailto:contact@brandeeq.com">contact@brandeeq.com</a> and
            share what’s on your mind.
          </p>
        </div>
        <div class="column has-text-centered">
          <img src="@/assets/img/case_studies/tourly/img-3.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuildBrands",
  data() {
    return {
      explainerHover: 1,
    };
  },

  methods: {
    setExplainerHover(val) {
      this.explainerHover = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.head-bg {
  background-color: #f6f6fa;
}
</style>
