<template>
  <div id="app" class="app-body">
    <div>
      <div class="spacer"></div>
      <div class="spacer-small"></div>
      <div class="columns">
        <div class="column">
          <p class="is-title is-secondary is-bold">
            Frequently Asked Questions
          </p>
          <p class="mt-2 mb-5">
            Got a question? We are here to help! If you do not see your question
            here, drop us a message on our
            <router-link :to="{ name: 'Contact' }"> Contact Page.</router-link>
          </p>
          <b-input
            v-model="filterQuery"
            class="mb-5"
            placeholder="Search..."
            type="search"
            icon="magnify"
          >
          </b-input>
          <b-collapse
            v-for="(faq, index) in filteredFaqs"
            :key="index"
            class="card mt-3"
            animation="slide"
            aria-id="contentIdForA11y3"
            :open="false"
          >
            <template #trigger="props">
              <div
                class="card-header"
                role="button"
                aria-controls="contentIdForA11y3"
                :aria-expanded="props.open"
              >
                <p class="card-header-title">Q. {{ faq.q }}</p>
                <a class="card-header-icon">
                  <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                  </b-icon>
                </a>
              </div>
            </template>

            <div class="card-content">
              <div class="content">
                <div v-html="faq.a"></div>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
      <div class="spacer-small"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQs",
  data() {
    return {
      filteredFaqs: [],
      filterQuery: undefined,
      faqs: [
        {
          q: "What is Brandeeq?",
          a: `<p>
                Brandeeq is the online place to build a new brand, or to
                evolve and improve an existing brand. You can access creative
                services in one place, and provides a methodology to get
                brand-building right, first time.
              </p>
              <p>
                Brandeeq is ideal for small business owners, or entrepreneurs
                starting a new venture.
              </p>`,
        },
        {
          q: "How do I use Brandeeq?",
          a: `<p>
                We suggest that you first learn about the Brandeeq method.
                Then, delve into some of some of our free, educational resources in our blog.
              </p>
              <p>
                When you are ready to begin, set-up your Brandeeq account
                and access our brand-building services. If for a new venture,
                the best place to start at is what you’re going to call your
                business For that, start with Brandeeq’s Brand Naming service.
              </p>
              `,
        },
        {
          q: "What are the benefits of Brandeeq?",
          a: `<p>
                If you believe that your organisation should have a distinct, unique brand that your customers can easily recall when they are actually buying, then Brandeeq is the brand-building service for you.
              </p>
              <p>
                The Brandeeq method will enable you to get brand-building right, first time. This will save you time and money, because when you’re doing it right first-time, then you do it once and are satisfied by the results.
              </p>
              <p>
                Once you’ve got it right, then you don’t alter or tinker with your brand assets: you just concentrate on promoting it to your target audience.
              </p>
              `,
        },
        {
          q: "How is Brandeeq different from other brand-building services?",
          a: `<p>
                Many online brand creation services use AI to generate assets. Sometimes this is OK, but they are, by design generic and typically inadequate to create distinct and unique brand assets. For example, they can’t fuse the cultural norms and practices related to brands and their markets. Nor are you asked the right questions, to generate the right answers, that leads to the right outcomes for you.
              </p>
              <p>
                Brandeeq is an online platform, but the work we deliver is done by qualified professionals. They work from the insights that you provide during our robust briefing questions. And you get to ask for refinements during the creative process.
              </p>
              `,
        },
        {
          q: "Which services does Brandeeq offer?",
          a: `<p>
                Brandeeq offers foundational brand-building services that help you create a distinct image for your brand identity from the start. The core services being offered include crafting a brand name, a slogan/tagline (or brand promise as we call it); creating logos, logo guidelines, and basic identity templates. There is also advice on company incorporation and trademark registration. You may purchase these services in their entirety, as individual offerings, or as value bundled offerings, for better value.
              </p>
              <p>
                Or, if you’re looking for a unique and customized service, you can explore that here. (add relevant hyperlink upon service launch.)
              </p>
              `,
        },
        {
          q: "What if I need only select brand creation services from Brandeeq?",
          a: `<p>
            If you’ve already created brand assets but you want to create new or additional ones using Brandeeq, then you can easily select the relevant services in your account.
              </p>
              <p>
                Our briefing process will ask you to confirm or provide any relevant, pre-existing brand assets that would inform our creative process.
              </p>
              `,
        },
        {
          q: "How will I receive my brand assets in Brandeeq?",
          a: `<p>
            We deliver all of your brand assets to your dedicated project space, within Brandeeq. You’ll receive an alert to tell you when there’s something to review and respond to.
              </p>
              <p>
                Additional, advisory services will be delivered via a mix of consultation and online delivery.
              </p>
              `,
        },
        {
          q: "Does Brandeeq offer other services?",
          a: `<p>
            Beyond core brand-building services, Brandeeq can also provide advisory services for both the trademark registration process (i.e. protecting the brand that you’re creating), or company formation (which usually requires some of the brand assets that we create at Brandeeq.)
              </p>
              <p>
                These advisory services are purchased in the Brandeeq portal, but their delivery relies on additional, direct contact with our specialists. They are particular to Pakistan.
              </p>
              `,
        },
        {
          q: "What are the costs?",
          a: `<p>
            Services are priced individually and are displayed during the ordering process. They are based upon the time involved in producing premier brand assets, that are distinct, unique, and protectible. You can also check-out our bundled offerings, which combines complementary brand services together, with savings.
              </p>
              `,
        },
        {
          q: "Are their discounts available?",
          a: `<p>
            Check-out our bundled offerings, which combines complementary brand services together, and offer savings on our single service prices.
              </p>
              `,
        },
        {
          q: " Is Brandeeq a Pakistani brand?",
          a: `<p>
            Brandeeq was founded by a George Noon, a British citizen, to help small business owners and entrepreneurs in Pakistan to get better at building their own brands. George has worked on brand-building for several new and existing Pakistani brands. He is a regular business visitor to Pakistan.
              </p>
              `,
        },
        {
          q: "Will I be assigned a dedicated professional?",
          a: `<p>
            Brandeeq has a team working behind the scenes. You will have contact with whichever professional/s are leading your project/s. There’s an in-built chat feature that lets you and your Brandeeq rep communicate during the creative process. Or, they will arrange a call with you, as required.
              </p>
              `,
        },
        {
          q: "How do I provide my input, to explain what I want?",
          a: `<p>
            The questions during the briefing stage of the process normally generates ample information about what you are looking for. If there’s additional context or details that you think that we should know, then there is an additional section available at the end of the briefing questions to facilitate that. Afterwards, our reps have the option to contact you for more info, either using our in-built chat function, or sometimes via a call.
              </p>
              `,
        },
        {
          q: "Q15. Does Brandeeq offer its services beyond Pakistan?",
          a: `<p>
            Yes, we do. While Brandeeq was conceived to support SMEs and start-ups in Pakistan to navigate common brand-building challenges, those issues are not unique to Pakistan. Anyone can access Brandeeq’s services, simply by opening an account.
              </p>
              `,
        },
        // {
        //   q: "XXXXXXX",
        //   a: `<p>
        //         XXXXXXXX
        //       </p>
        //       <p>
        //         XXXXXXXX
        //       </p>
        //       `,
        // },
      ],
    };
  },
  created() {
    this.filteredFaqs = this.faqs;
  },
  watch: {
    filterQuery() {
      this.filter(this.filterQuery);
    },
  },
  methods: {
    filter(query) {
      this.filteredFaqs = this.faqs.filter((faq) =>
        faq.q
          .toLowerCase()
          .concat(faq.a.toLowerCase())
          .includes(query.toLowerCase())
      );
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
