<template>
  <div id="app" class="app-body">
    <div>
      <div class="spacer"></div>
      <div class="columns is-vcentered">
        <div class="column">
          <p class="is-title is-secondary is-bold">Privacy Policy</p>
          <div class="spacer"></div>
          <p class="my-4">1. <strong>Introduction</strong></p>
          <p class="my-4">
            Welcome to <strong>Brandeeq Private Limited</strong>.
          </p>
          <p class="my-4">
            <strong>Brandeeq Private Limited</strong> (&ldquo;us&rdquo;,
            &ldquo;we&rdquo;, or &ldquo;our&rdquo;) operates
            <strong>www.brandeeq.com</strong> (hereinafter referred to as
            <strong>&ldquo;Service&rdquo;</strong>).
          </p>
          <p class="my-4">
            Our Privacy Policy governs your visit to
            <strong>www.brandeeq.com</strong>, and explains how we collect,
            safeguard and disclose information that results from your use of our
            Service.
          </p>
          <p class="my-4">
            We use your data to provide and improve Service. By using Service,
            you agree to the collection and use of information in accordance
            with this policy. Unless otherwise defined in this Privacy Policy,
            the terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions.
          </p>
          <p class="my-4">
            Our Terms and Conditions (<strong>&ldquo;Terms&rdquo;</strong>)
            govern all use of our Service and together with the Privacy Policy
            constitutes your agreement with us
            (<strong>&ldquo;agreement&rdquo;</strong>).
          </p>
          <p class="my-4">2. <strong>Definitions</strong></p>
          <p class="my-4">
            <strong>SERVICE</strong> means the www.brandeeq.com website operated
            by Brandeeq Private Limited.
          </p>
          <p class="my-4">
            <strong>PERSONAL DATA</strong> means data about a living individual
            who can be identified from those data (or from those and other
            information either in our possession or likely to come into our
            possession).
          </p>
          <p class="my-4">
            <strong>USAGE DATA</strong> is data collected automatically either
            generated by the use of Service or from Service infrastructure
            itself (for example, the duration of a page visit).
          </p>
          <p class="my-4">
            <strong>COOKIES</strong> are small files stored on your device
            (computer or mobile device).
          </p>
          <p class="my-4">
            <strong>DATA CONTROLLER</strong> means a natural or legal person who
            (either alone or jointly or in common with other persons) determines
            the purposes for which and the manner in which any personal data
            are, or are to be, processed. For the purpose of this Privacy
            Policy, we are a Data Controller of your data.
          </p>
          <p class="my-4">
            <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means any
            natural or legal person who processes the data on behalf of the Data
            Controller. We may use the services of various Service Providers in
            order to process your data more effectively.
          </p>
          <p class="my-4">
            <strong>DATA SUBJECT</strong> is any living individual who is the
            subject of Personal Data.
          </p>
          <p class="my-4">
            <strong>THE USER</strong> is the individual using our Service. The
            User corresponds to the Data Subject, who is the subject of Personal
            Data.
          </p>
          <p class="my-4">3. <strong>Information Collection and Use</strong></p>
          <p class="my-4">
            We collect several different types of information for various
            purposes to provide and improve our Service to you.
          </p>
          <p class="my-4">4. <strong>Types of Data Collected</strong></p>
          <p class="my-4"><strong>Personal Data</strong></p>
          <p class="my-4">
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you (<strong>&ldquo;Personal Data&rdquo;</strong>).
            Personally identifiable information may include, but is not limited
            to:
          </p>
          <ol class="ol1">
            <ol class="ol1">
              <li class="li2">Email address</li>
              <li class="li2">First name and last name</li>
              <li class="li2">
                Company&rsquo;s area of business<span
                  class="Apple-converted-space"
                  >&nbsp;</span
                >
              </li>
              <li class="li2">Phone number</li>
              <li class="li2">
                Address, Country, State, Province, ZIP/Postal code, City
              </li>
              <li class="li2">Cookies and Usage Data</li>
            </ol>
          </ol>
          <p class="my-4">
            We may use your Personal Data to contact you with newsletters,
            marketing or promotional materials and other information that may be
            of interest to you. You may opt out of receiving any, or all, of
            these communications from us by following the unsubscribe link.
          </p>
          <p class="my-4"><strong>Usage Data</strong></p>
          <p class="my-4">
            We may also collect information that your browser sends whenever you
            visit our Service or when you access Service by or through any
            device (<strong>&ldquo;Usage Data&rdquo;</strong>).
          </p>
          <p class="my-4">
            This Usage Data may include information such as your
            computer&rsquo;s Internet Protocol address (e.g. IP address),
            browser type, browser version, the pages of our Service that you
            visit, the time and date of your visit, the time spent on those
            pages, unique device identifiers and other diagnostic data.
          </p>
          <p class="my-4">
            When you access Service with a device, this Usage Data may include
            information such as the type of device you use, your device unique
            ID, the IP address of your device, your device operating system, the
            type of Internet browser you use, unique device identifiers and
            other diagnostic data.
          </p>
          <p class="my-4"><strong>Tracking Cookies Data</strong></p>
          <p class="my-4">
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
          </p>
          <p class="my-4">
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyze our Service.
          </p>
          <p class="my-4">
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </p>
          <p class="my-4">Examples of Cookies we use:</p>
          <ol class="ol1">
            <ol class="ol1">
              <li class="li2">
                <strong>Session Cookies:</strong> We use Session Cookies to
                operate our Service.
              </li>
              <li class="li2">
                <strong>Preference Cookies:</strong> We use Preference Cookies
                to remember your preferences and various settings.
              </li>
              <li class="li2">
                <strong>Security Cookies:</strong> We use Security Cookies for
                security purposes.
              </li>
              <li class="li2">
                <strong>Advertising Cookies:</strong> Advertising Cookies are
                used to serve you with advertisements that may be relevant to
                you and your interests.
              </li>
            </ol>
          </ol>
          <p class="my-4"><strong>Other Data</strong></p>
          <p class="my-4">
            While using our Service, we may also collect the following
            information: sex, age, date of birth, place of birth, passport
            details, citizenship, registration at place of residence and actual
            address, telephone number (work, mobile), details of documents on
            education, qualification, professional training, employment
            agreements,
            <a href="https://policymaker.io/non-disclosure-agreement/"
              ><span class="s1">NDA agreements</span></a
            >, information on bonuses and compensation, information on marital
            status, family members, social security (or other taxpayer
            identification) number, office location and other data.
          </p>
          <p class="my-4">5. <strong>Use of Data</strong></p>
          <p class="my-4">
            Brandeeq Private Limited uses the collected data for various
            purposes:
          </p>
          <ol class="ol1">
            <ol class="ol1">
              <li class="li2">to provide and maintain our Service;</li>
              <li class="li2">to notify you about changes to our Service;</li>
              <li class="li2">
                to allow you to participate in interactive features of our
                Service when you choose to do so;
              </li>
              <li class="li2">to provide customer support;</li>
              <li class="li2">
                to gather analysis or valuable information so that we can
                improve our Service;
              </li>
              <li class="li2">to monitor the usage of our Service;</li>
              <li class="li2">
                to detect, prevent and address technical issues;
              </li>
              <li class="li2">
                to fulfil any other purpose for which you provide it;
              </li>
              <li class="li2">
                to carry out our obligations and enforce our rights arising from
                any contracts entered into between you and us, including for
                billing and collection;
              </li>
              <li class="li2">
                to provide you with notices about your account and/or
                subscription, including expiration and renewal notices,
                email-instructions, etc.;
              </li>
              <li class="li2">
                to provide you with news, special offers and general information
                about other goods, services and events which we offer that are
                similar to those that you have already purchased or enquired
                about unless you have opted not to receive such information;
              </li>
              <li class="li2">
                in any other way we may describe when you provide the
                information;
              </li>
              <li class="li2">for any other purpose with your consent.</li>
            </ol>
          </ol>
          <p class="my-4">6. <strong>Retention of Data</strong></p>
          <p class="my-4">
            We will retain your Personal Data only for as long as is necessary
            for the purposes set out in this Privacy Policy. We will retain and
            use your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your
            data to comply with applicable laws), resolve disputes, and enforce
            our legal agreements and policies.
          </p>
          <p class="my-4">
            We will also retain Usage Data for internal analysis purposes. Usage
            Data is generally retained for a shorter period, except when this
            data is used to strengthen the security or to improve the
            functionality of our Service, or we are legally obligated to retain
            this data for longer time periods.
          </p>
          <p class="my-4">7. <strong>Transfer of Data</strong></p>
          <p class="my-4">
            Your information, including Personal Data, may be transferred to
            &ndash; and maintained on &ndash; computers located outside of your
            state, province, country or other governmental jurisdiction where
            the data protection laws may differ from those of your jurisdiction.
          </p>
          <p class="my-4">
            If you are located outside Islamic Republic of Pakistan and choose
            to provide information to us, please note that we transfer the data,
            including Personal Data, to Islamic Republic of Pakistan and process
            it there.
          </p>
          <p class="my-4">
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p class="my-4">
            Brandeeq Private Limited will take all the steps reasonably
            necessary to ensure that your data is treated securely and in
            accordance with this Privacy Policy and no transfer of your Personal
            Data will take place to an organisation or a country unless there
            are adequate controls in place including the security of your data
            and other personal information.
          </p>
          <p class="my-4">8. <strong>Disclosure of Data</strong></p>
          <p class="my-4">
            We may disclose personal information that we collect, or you
            provide:
          </p>
          <p class="my-4">0.1. <strong>Business Transaction.</strong></p>
          <p class="my-4">
            If we or our subsidiaries are involved in a merger, acquisition or
            asset sale, your Personal Data may be transferred.
          </p>
          <p class="my-4">
            0.2.
            <strong>Other cases. We may disclose your information also:</strong>
          </p>
          <p class="my-4">0.2.1. to our subsidiaries and affiliates;</p>
          <p class="my-4">
            0.2.2. to contractors, service providers, and other third parties we
            use to support our business;
          </p>
          <p class="my-4">
            0.2.3. to fulfill the purpose for which you provide it;
          </p>
          <p class="my-4">
            0.2.4. for the purpose of including your company&rsquo;s logo on our
            website;
          </p>
          <p class="my-4">
            0.2.5. for any other purpose disclosed by us when you provide the
            information;
          </p>
          <p class="my-4">0.2.6. with your consent in any other cases;</p>
          <p class="my-4">
            0.2.7. if we believe disclosure is necessary or appropriate to
            protect the rights, property, or safety of the Company, our
            customers, or others.
          </p>
          <p class="my-4">9. <strong>Security of Data</strong></p>
          <p class="my-4">
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
          <p class="my-4">
            10.
            <strong
              >Your Data Protection Rights Under General Data Protection
              Regulation (GDPR)<span class="Apple-converted-space"
                >&nbsp;</span
              ></strong
            >
          </p>
          <p class="my-4">
            If you are a resident of the European Union (EU) and European
            Economic Area (EEA), you have certain data protection rights,
            covered by GDPR.
          </p>
          <p class="my-4">
            We aim to take reasonable steps to allow you to correct, amend,
            delete, or limit the use of your Personal Data.
          </p>
          <p class="my-4">
            If you wish to be informed what Personal Data we hold about you and
            if you want it to be removed from our systems, please email us at
            <strong>contact@brandeeq.com</strong>.
          </p>
          <p class="my-4">
            In certain circumstances, you have the following data protection
            rights:
          </p>
          <p class="my-4">
            0.1. the right to access, update or to delete the information we
            have on you;
          </p>
          <p class="my-4">
            0.2. the right of rectification. You have the right to have your
            information rectified if that information is inaccurate or
            incomplete;
          </p>
          <p class="my-4">
            0.3. the right to object. You have the right to object to our
            processing of your Personal Data;
          </p>
          <p class="my-4">
            0.4. the right of restriction. You have the right to request that we
            restrict the processing of your personal information;
          </p>
          <p class="my-4">
            0.5. the right to data portability. You have the right to be
            provided with a copy of your Personal Data in a structured,
            machine-readable and commonly used format;
          </p>
          <p class="my-4">
            0.6. the right to withdraw consent. You also have the right to
            withdraw your consent at any time where we rely on your consent to
            process your personal information;
          </p>
          <p class="my-4">
            Please note that we may ask you to verify your identity before
            responding to such requests. Please note, we may not able to provide
            Service without some necessary data.
          </p>
          <p class="my-4">
            You have the right to complain to a Data Protection Authority about
            our collection and use of your Personal Data. For more information,
            please contact your local data protection authority in the European
            Economic Area (EEA).
          </p>
          <p class="my-4">
            <strong
              >0.1. What personal information we have about you. If you make
              this request, we will return to you:</strong
            >
          </p>
          <p class="my-4">
            0.0.1. The categories of personal information we have collected
            about you.
          </p>
          <p class="my-4">
            0.0.2. The categories of sources from which we collect your personal
            information.
          </p>
          <p class="my-4">
            0.0.3. The business or commercial purpose for collecting or selling
            your personal information.
          </p>
          <p class="my-4">
            0.0.4. The categories of third parties with whom we share personal
            information.
          </p>
          <p class="my-4">
            0.0.5. The specific pieces of personal information we have collected
            about you.
          </p>
          <p class="my-4">
            0.0.6. A list of categories of personal information that we have
            sold, along with the category of any other company we sold it to. If
            we have not sold your personal information, we will inform you of
            that fact.
          </p>
          <p class="my-4">
            0.0.7. A list of categories of personal information that we have
            disclosed for a business purpose, along with the category of any
            other company we shared it with.
          </p>
          <p class="my-4">
            Please note, you are entitled to ask us to provide you with this
            information up to two times in a rolling twelve-month period. When
            you make this request, the information provided may be limited to
            the personal information we collected about you in the previous 12
            months.
          </p>
          <p class="my-4">
            <strong
              >0.2. To delete your personal information. If you make this
              request, we will delete the personal information we hold about you
              form our records within five working days of the date of your
              request and direct any service providers to do the same. In some
              cases, deletion may be accomplished through de-identification of
              the information. If you choose to delete your personal
              information, you may not be able to use certain functions that
              require your personal information to operate.</strong
            >
          </p>
          <p class="my-4">
            <strong
              >0.3. To stop selling your personal information. We don&rsquo;t
              sell or rent your personal information to any third parties for
              any purpose. We do not sell your personal information for monetary
              consideration. However, under some circumstances, a transfer of
              personal information to a third party, or within our family of
              companies, without monetary consideration may be considered a
              &ldquo;sale&rdquo; under California law. You are the only owner of
              your Personal Data and can request disclosure or deletion at any
              time.</strong
            >
          </p>
          <p class="my-4">
            If you submit a request to stop selling your personal information,
            we will stop making such transfers.
          </p>
          <p class="my-4">
            Please note, if you ask us to delete or stop selling your data, it
            may impact your experience with us, and you may not be able to
            participate in certain programs or membership services which require
            the usage of your personal information to function. But in no
            circumstances, we will discriminate against you for exercising your
            rights.
          </p>
          <p class="my-4">
            To exercise your California data protection rights described above,
            please send your request(s) by email:
            <strong>contact@brandeeq.com</strong>.
          </p>
          <p class="my-4">
            Your data protection rights, described above, are covered by the
            CCPA, short for the California Consumer Privacy Act. To find out
            more, visit the official California Legislative Information website.
            The CCPA took effect on 01/01/2020.
          </p>
          <p class="my-4">13. <strong>Service Providers</strong></p>
          <p class="my-4">
            We may employ third party companies and individuals to facilitate
            our Service (<strong>&ldquo;Service Providers&rdquo;</strong>),
            provide Service on our behalf, perform Service-related services or
            assist us in analysing how our Service is used.
          </p>
          <p class="my-4">
            These third parties have access to your Personal Data only to
            perform these tasks on our behalf and are obligated not to disclose
            or use it for any other purpose.
          </p>
          <p class="my-4">14. <strong>Analytics</strong></p>
          <p class="my-4">
            We may use third-party Service Providers to monitor and analyze the
            use of our Service.
          </p>
          <p class="my-4">15. <strong>CI/CD tools</strong></p>
          <p class="my-4">
            We may use third-party Service Providers to automate the development
            process of our Service.
          </p>
          <p class="my-4">16. <strong>Behavioral Remarketing</strong></p>
          <p class="my-4">
            We may use remarketing services to advertise on third party websites
            to you after you visited our Service. We and our third-party vendors
            use cookies to inform, optimise and serve ads based on your past
            visits to our Service.
          </p>
          <p class="my-4">17. <strong>Payments</strong></p>
          <p class="my-4">
            We may provide paid products and/or services within Service. In that
            case, we use third-party services for payment processing (e.g.
            payment processors).
          </p>
          <p class="my-4">
            We will not store or collect your payment card details. That
            information is provided directly to our third-party payment
            processors whose use of your personal information is governed by
            their Privacy Policy. These payment processors adhere to the
            standards set by PCI-DSS as managed by the PCI Security Standards
            Council, which is a joint effort of brands like Visa, Mastercard,
            American Express and Discover. PCI-DSS requirements help ensure the
            secure handling of payment information.
          </p>
          <p class="my-4">18. <strong>Links to Other Sites</strong></p>
          <p class="my-4">
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            third party&rsquo;s site. We strongly advise you to review the
            Privacy Policy of every site you visit.
          </p>
          <p class="my-4">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <p class="my-4">19. <strong>Children&rsquo;s Privacy</strong></p>
          <p class="my-4">
            Our Services are not intended for use by children under the age of
            18 (<strong>&ldquo;Child&rdquo;</strong> or
            <strong>&ldquo;Children&rdquo;</strong>).
          </p>
          <p class="my-4">
            We do not knowingly collect personally identifiable information from
            Children under 18. If you become aware that a Child has provided us
            with Personal Data, please contact us. If we become aware that we
            have collected Personal Data from Children without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </p>
          <p class="my-4">
            20. <strong>Changes to This Privacy Policy</strong>
          </p>
          <p class="my-4">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p class="my-4">
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update
            &ldquo;effective date&rdquo; at the top of this Privacy Policy.
          </p>
          <p class="my-4">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <p class="my-4">21. <strong>Contact Us</strong></p>
          <p class="my-4">
            If you have any questions about this Privacy Policy, please contact
            us by email: <strong>contact@brandeeq.com</strong>.
          </p>
          <div class="spacer"></div>
          <p class="my-4 text-small">Effective date: 2022-09-04</p>
        </div>
      </div>
      <div class="spacer"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
