<template>
  <div id="app" class="app-body">
    <div>
      <div class="spacer"></div>
      <div class="columns is-vcentered head-bg p-0 m-0">
        <div class="column has-text-white" style="padding: 54px">
          <p class="is-subtitle-small">Case Study</p>
          <p class="is-title is-bold">Kestral</p>
        </div>
        <div
          class="column has-text-right m-0"
          style="
            display: flex;
            justify-content: end;
            padding: 45px 30px 0px 0px;
          "
        >
          <img
            src="@/assets/img/case_studies/kestral/hero.png"
            class="mt-5"
            width="80%"
          />
        </div>
      </div>

      <div class="spacer-small"></div>
      <div class="columns">
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">Introduction</p>
          <p class="mt-4">
            Kestral is a trading company and well-known brand in both domestic
            and international defence and security circles. The firm also
            supports humanitarian relief efforts, in Pakistan and abroad. The
            company has been operating for over thirty years. Kestral’s founder
            and owner also invests in start-up incubators.
          </p>
          <p class="is-title is-secondary is-bold mt-5 pt-5">Challenge</p>
          <p class="mt-4">
            Kestral’s brand challenge was in maintaining the consistency and
            integrity of its brand assets. Multiple variations of the visual
            identity were in circulation: different dimensions, multiple
            typefaces, color coding variations and antiquated design components.
          </p>
          <p class="mt-4">
            This happen when there is no brand expert curating the brand; a lack
            of a brand asset management system; and the absence of best
            practices for brand management, reinforced through employee
            education.
          </p>
        </div>
        <div class="column has-text-centered">
          <img src="@/assets/img/case_studies/kestral/img-1.svg" />
        </div>
      </div>

      <div class="spacer"></div>
      <div class="columns">
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">Our Response</p>

          <p class="mt-4">
            First, Brandeeq conducted an audit of Kestral’s physical and digital
            spheres, to capture all cases of the logomark in use, and analyse
            those.
          </p>
          <p class="mt-4">
            Second, we ran a creative process, with the goal of arriving at one,
            modern master version of Kestral’s logomark. This end-goal was not
            to create an entirely new logo; rather, it was the collation of
            multiple variants, plus the brand owner’s input, to arrive at one,
            contemporary version.
          </p>
          <p class="mt-4">
            And finally, the creation of both logo and visual identity
            guidelines, templates, and a digital management system, to make all
            of these easily accessible to employees, contractors and select
            external stakeholders.
          </p>
        </div>
        <div class="column has-text-centered">
          <img src="@/assets/img/case_studies/kestral/img-2.svg" />
        </div>
      </div>
      <div class="spacer"></div>

      <div class="columns">
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">Results</p>
          <p class="mt-4">Founder & CEO, Liaquat Ali Baig, said:</p>

          <p class="mt-4 speech">
            “Consider the effort each time someone needs the Kestral logo or
            visual identity standards, but doesn’t know where to access those,
            or how to apply them properly.
          </p>
          <p class="mt-4 speech">
            Thanks to Brandeeq, we have one master logo, one set of visual
            identity standards, and an established process to apply those
            correctly and consistently in every situation.“
          </p>
          <div class="spacer-small"></div>
          <p>
            Are you intrigued? Message us at
            <a href="mailto:contact@brandeeq.com">contact@brandeeq.com</a> and
            share what’s on your mind.
          </p>
        </div>
        <div class="column has-text-centered">
          <img src="@/assets/img/case_studies/kestral/img-3.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuildBrands",
  data() {
    return {
      explainerHover: 1,
    };
  },

  methods: {
    setExplainerHover(val) {
      this.explainerHover = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.head-bg {
  background: linear-gradient(156deg, #036 0%, #036 30%, #918811 100%);
}
</style>
