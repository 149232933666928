<template>
  <div id="app" class="app-body">
    <div>
      <div class="spacer"></div>
      <div class="columns">
        <div class="column">
          <p class="is-title is-secondary is-bold">Pricing</p>
          <p class="mt-2">
            Simple straight-forward packages to suit your branding needs.
          </p>
          <div class="spacer"></div>

          <p class="is-secondary is-bold mb-5 pb-5">Creative Writing</p>

          <div class="columns is-multiline">
            <div
              v-for="(service, index) in writingServices"
              :key="index"
              class="column is-3"
            >
              <div class="box">
                <div>
                  <ServiceIllustrations
                    :service="service.icon ? service.icon : service.name"
                  />
                </div>
                <p class="is-subtitle-small is-secondary is-bold">
                  {{ service.name }}
                </p>
                <p class="text-small mt-3">
                  {{ service.description }}
                </p>
                <p class="text-medium mt-3 is-bold">{{ service.price }}</p>
              </div>
            </div>
          </div>

          <p class="is-secondary is-bold my-5 py-5">Creative Design</p>

          <div class="columns is-multiline">
            <div
              v-for="(service, index) in designServices"
              :key="index"
              class="column is-3"
            >
              <div class="box">
                <div>
                  <ServiceIllustrations
                    :service="service.icon ? service.icon : service.name"
                  />
                </div>
                <p class="is-subtitle-small is-secondary is-bold">
                  {{ service.name }}
                </p>
                <p class="text-small mt-3">
                  {{ service.description }}
                </p>
                <p class="text-medium mt-3 is-bold">{{ service.price }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="spacer"></div>
    </div>
  </div>
</template>

<script>
import ServiceIllustrations from "../../components/misc/ServiceIllustrations.vue";

export default {
  name: "BuildBrands",
  components: { ServiceIllustrations },
  data() {
    return {
      writingServices: [
        {
          name: "Brand Name",
          description:
            "Five distinct brand name options. One revision round. One preferred name. Record in Brandeeq register.",
          price: "39,000 PKR",
        },
        {
          name: "Brand Promise",
          description:
            "Five distinct brand promise options.One revision round. One preferred name. Record in Brandeeq register.",
          price: "39,000 PKR",
        },
      ],
      designServices: [
        {
          name: "Brand Logo",
          description:
            "Two distinct brand logo options. One revision round. One preferred brand logo. Record in Brandeeq register.",
          price: "49,000 PKR",
        },
        {
          name: "Logo Guide",
          icon: "Trademark Registration",
          description:
            "Guide containing: master version + mono-colour variants; dimensions, typeface, colour refs, dos & don'ts.",
          price: "29,000 PKR",
        },
        {
          name: "Brand Templates",
          description:
            "Letterhead, internal info & business card designs. One revision round.",
          price: "29,000 PKR",
        },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
