<template>
  <div id="app" class="app-body">
    <div>
      <div class="spacer"></div>
      <div class="columns is-vcentered head-bg p-0 m-0">
        <div class="column has-text-white" style="padding: 54px">
          <p class="is-subtitle-small">Case Study</p>
          <p class="is-title is-bold">Kite Kitchen</p>
        </div>
        <div
          class="column has-text-right p-0 m-0"
          style="display: flex; justify-content: end"
        >
          <img src="@/assets/img/case_studies/kite_kitchen/hero.png" />
        </div>
      </div>

      <div class="spacer-small"></div>
      <div class="columns">
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">Introduction</p>
          <p class="mt-4">KITE Kitchen was conceived during Covid times.</p>
          <p class="mt-4">
            It started as a home-cooking distribution service, for the twin
            cities. Then, its owners pivoted to cooking their own food, offering
            both dine-in and take-away.
          </p>
          <p class="mt-4">
            Presently, there are KITE Kitchen units in Rawalpindi and the CUST
            campus.
          </p>
          <p class="is-title is-secondary is-bold mt-5 pt-5">Challenge</p>
          <p class="mt-4">
            To create an original logomark and a visual identity that could be
            distinct in the bustling Food & Beverages (F&B) sector. F&B is
            always brimming with both established and emerging brands.
          </p>
          <p class="mt-4">
            The new identity would have to be practically applicable to a wide
            range of business assets. While KITE Kitchen’s owners wanted their
            master brand to be reflected in the Kite Kitchen logomark.
          </p>
          <p class="mt-4">
            F&B brands in Pakistan are dominated by the colour red with four
            main shades prevalent; and we know this because we did the research.
            This presents a quandry for a new F&B brand: do what most brands do
            and conform to a status quo; or step-out, but potentially disrupt
            most people’s frame of refence that subconsciously associates F&B
            with red.
          </p>
        </div>
        <div class="column has-text-centered">
          <img src="@/assets/img/case_studies/kite_kitchen/img-1.svg" />
        </div>
      </div>

      <div class="spacer"></div>
      <div class="columns">
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">Our Response</p>

          <p class="mt-4">
            An unfussy logomark, inspired by the iconic dome shape of the
            classic silver service dish. The dome houses the KITE monogram, with
            the word Kitchen placed underneath. The overall visual identity is
            driven by a single, strong colour Vivid Burgundy and typeface Gotham
            Rounded, illustrating a harmonious composition.
          </p>
          <p class="mt-4">
            As to the colour conundrum, the solution proved to be something
            in-between: Kite Kitchen did step out from classic reds, but stayed
            close enough to that colour family to keep enabling pre-existing
            links to the F&B sector.
          </p>
        </div>
        <div class="column has-text-centered">
          <img src="@/assets/img/case_studies/kite_kitchen/img-2.svg" />
        </div>
      </div>
      <div class="spacer"></div>

      <div class="columns">
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">Results</p>
          <p class="mt-4">Former Kite Kitchen GM, Ali Hamza Khan, said:</p>

          <p class="mt-4 speech">
            “We really liked the two strong concepts that Brandeeq brought to
            us. The one we settled on works across all of our customer
            touchpoints - from signs, to menus to delivery boxes.
          </p>
          <p class="mt-4 speech">
            One of the things that sets Brandeeq apart is that their focus is
            not only on the design concept; they’re as focused on the consistent
            application of the identity after its introduced.”
          </p>
          <div class="spacer-small"></div>
          <p>
            Are you intrigued? Message us at
            <a href="mailto:contact@brandeeq.com">contact@brandeeq.com</a> and
            share what’s on your mind.
          </p>
        </div>
        <div class="column has-text-centered">
          <img src="@/assets/img/case_studies/kite_kitchen/img-3.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuildBrands",
  data() {
    return {
      explainerHover: 1,
    };
  },

  methods: {
    setExplainerHover(val) {
      this.explainerHover = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.head-bg {
  background: linear-gradient(156deg, #a4193d 0%, #f7931e 100%);
}
</style>
