<template>
  <div id="app" class="app-body">
    <div>
      <div class="spacer"></div>
      <div class="columns hero-bg">
        <div class="column is-1"></div>
        <div class="column has-text-centered has-text-white">
          <p class="is-title is-bold my-5 py-5">Case Studies</p>
          <p class="my-5">
            Wondering if we can deliver on what we say? Well, no need to take
            our word for it. Read what our clients say about working with
            Brandeeq, in our selection of case studies below.
          </p>
          <p class="my-5">
            <i class="mdi mdi-arrow-down"></i> View Case Studies
          </p>

          <div
            class="columns mt-5 pt-5 is-mobile is-hidden-mobile logo-white pb-5"
          >
            <div class="column is-2 is-hidden-mobile"></div>
            <div class="column has-text-centered">
              <img src="@/assets/img/home/tourly.svg" width="90%" />
            </div>
            <div class="column has-text-centered is-hidden-mobile">
              <img src="@/assets/img/home/kite_kitchen.svg" width="90%" />
            </div>
            <div class="column has-text-centered">
              <img src="@/assets/img/home/gigtal.svg" width="90%" />
            </div>
            <div class="column has-text-centered">
              <img src="@/assets/img/home/iinix.svg" width="90%" />
            </div>
            <div class="column has-text-centered is-hidden-mobile">
              <img src="@/assets/img/home/breakfast_cart.svg" width="90%" />
            </div>
            <div class="column has-text-centered">
              <img src="@/assets/img/home/roads.svg" width="90%" />
            </div>
            <div class="column is-2 is-hidden-mobile"></div>
          </div>
        </div>
        <div class="column is-1"></div>
      </div>

      <div class="spacer-small"></div>

      <div class="columns is-vcentered">
        <div class="column has-text-centered">
          <img src="@/assets/img/logos/color/kite_kitchen.svg" width="50%" />
        </div>
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">Kite Kitchen</p>
          <p class="mt-4">
            Brandeeq was tasked with creating an original logomark and visual
            identity that could be distinct in the bustling Food & Beverages
            (F&B) sector.
          </p>
          <router-link :to="{ name: 'KiteKitchenCaseStudy' }">
            <b-button type="is-info is-light" class="mt-4">
              View Case Study
            </b-button>
          </router-link>
        </div>
      </div>

      <div class="columns is-vcentered">
        <div class="column has-text-centered">
          <img src="@/assets/img/logos/color/roads.svg" width="50%" />
        </div>
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">ROADS</p>
          <p class="mt-4">
            ROADS had selected its name, and had a version of a logomark, when
            it first came into contact with Brandeeq.
          </p>
          <router-link :to="{ name: 'RoadsCaseStudy' }">
            <b-button type="is-info is-light" class="mt-4">
              View Case Study
            </b-button>
          </router-link>
        </div>
      </div>

      <div class="columns is-vcentered">
        <div class="column has-text-centered">
          <img src="@/assets/img/logos/color/kestral.svg" width="50%" />
        </div>
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">Kestral</p>
          <p class="mt-4">
            Kestral engaged Brandeeq to create a brand identity system to
            maintain consistency and the integrity of its brand assets.
          </p>
          <router-link :to="{ name: 'KestralCaseStudy' }">
            <b-button type="is-info is-light" class="mt-4">
              View Case Study
            </b-button>
          </router-link>
        </div>
      </div>

      <div class="columns is-vcentered">
        <div class="column has-text-centered">
          <img src="@/assets/img/logos/color/iinix.svg" width="50%" />
        </div>
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">IINIX</p>
          <p class="mt-4">
            To mark its 20th year in business, IINIX turned to Brandeeq for a
            new, contemporary wordmark (logo), and a supporting visual identity.
          </p>
          <router-link :to="{ name: 'IinixCaseStudy' }">
            <b-button type="is-info is-light" class="mt-4">
              View Case Study
            </b-button>
          </router-link>
        </div>
      </div>

      <div class="columns is-vcentered">
        <div class="column has-text-centered">
          <img src="@/assets/img/logos/color/tourly.svg" width="50%" />
        </div>
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">Tourly</p>
          <p class="mt-4">
            Tourly wanted a Brand Promise with a strong point of
            differentiation, so it called upon Brandeeq to create one.
          </p>
          <router-link :to="{ name: 'TourlyCaseStudy' }">
            <b-button type="is-info is-light" class="mt-4">
              View Case Study
            </b-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuildBrands",
  data() {
    return {
      explainerHover: 1,
    };
  },

  methods: {
    setExplainerHover(val) {
      this.explainerHover = val;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.hero-bg {
  background-image: url("../../assets/img/case_studies/hero.svg");
  border-radius: 8px;
  background-size: cover;
  background-position: center;
}

.logo-white {
  filter: brightness(0) invert(1);
}
</style>
