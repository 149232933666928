<template>
  <div id="app" class="app-body">
    <div>
      <div class="spacer"></div>
      <div class="columns is-vcentered head-bg p-0 m-0">
        <div class="column has-text-white" style="padding: 54px">
          <p class="is-subtitle-small">Case Study</p>
          <p class="is-title is-bold">IINIX</p>
        </div>
        <div
          class="column has-text-right p-0 m-0 pt-5"
          style="display: flex; justify-content: end"
        >
          <img
            style="margin-top: 100px; min-width: 450px"
            src="@/assets/img/case_studies/iinix/hero.svg"
          />
        </div>
      </div>

      <div class="spacer-small"></div>
      <div class="columns">
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">Introduction</p>
          <p class="mt-4">
            IT network solutions provider, IINIX, has been operating for two
            decades. Operating internationally from Pakistan, iinix delivers
            solutions that combine ease-of-use, performance, and security. The
            brand is driven by values including speed and innovation.
          </p>

          <p class="is-title is-secondary is-bold mt-5 pt-5">Challenge</p>
          <p class="mt-4">
            To mark its 20th year in business, IINIX’s founder and owner turned
            to Brandeeq to develop a new, contemporary wordmark (logo), and a
            supporting visual identity, to better represent what the brand has
            become and aspires to be in the future.
          </p>
        </div>
        <div class="column has-text-centered">
          <img src="@/assets/img/case_studies/iinix/img-1.svg" />
        </div>
      </div>

      <div class="spacer"></div>
      <div class="columns">
        <div class="column">
          <p class="is-title is-secondary is-bold">Our Response</p>
          <p class="mt-4">
            Brandeeq presented two viable concepts, in response to the brief
            created by IINIX in our portal.
          </p>
          <p class="mt-4">
            IINIX’s owner opted for the bold and sharp typeface of the second
            option, which centres on conveying a sense of future. The
            contemporary colour scheme of oranges and blues portrays trust and
            innovation. Guidelines were provided, to ensure consistent
            application in every situation.
          </p>
        </div>
        <div class="column has-text-centered">
          <img src="@/assets/img/case_studies/iinix/img-2.svg" />
        </div>
      </div>
      <div class="spacer"></div>

      <div class="columns">
        <div class="column">
          <p class="is-title is-secondary is-bold mt-4">Results</p>
          <p class="mt-4">IINIX founder & owner, Saajid Zaidi:</p>
          <p class="mt-4 speech">
            “IINIX is twenty years old. It made me think about our brand and
            whether our logo best represents what we have become today.
          </p>
          <p class="mt-4 speech">
            I’m so glad we did the work with the Brandeeq team, for the
            thoroughly professional approach and a truly professional result.
            I’m really happy with our refreshed logo (and guidelines) and the
            updated visual identity. It is already applied in some of our
            business templates – we used Brandeeq for that too - and now we’re
            rolling these out across other, digital assets.
          </p>
          <p class="mt-4 speech">
            The Brandeeq portal is simple to use, the creative process is an
            efficient one and the team is friendly and easy to work with.”
          </p>
          <div class="spacer-small"></div>
          <p>
            Are you intrigued? Message us at
            <a href="mailto:contact@brandeeq.com">contact@brandeeq.com</a> and
            share what’s on your mind.
          </p>
        </div>
        <div class="column has-text-centered py-5">
          <img src="@/assets/img/case_studies/iinix/img-3.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BuildBrands",
  data() {
    return {
      explainerHover: 1,
    };
  },

  methods: {
    setExplainerHover(val) {
      this.explainerHover = val;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/main.scss";

.head-bg {
  background: linear-gradient(152deg, #ea9928 0%, #edb364 100%);
}
</style>
